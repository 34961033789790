export const messages = {
    sv: {
        home: {
            header: 'Välkommen till Sibylla!'
        },
        article:{
            allergens: '<span>Allergisk? Läs mer om innehåll och allergener </span><a href="https://www.sibylla.se/var-mat/allergener/">här.</a>'
        },
        label: {
            dineInPlace: 'Äta på restaurangen',
            takeAway: 'Ta med',
            articleChoiceLabel1: 'Extra burgare', 
            articleChoiceLabel2: 'Anpassa',
            articleChoiceLabel3: 'Välj tillbehör',
            articleChoiceLabel4: 'Ändra dryck',
            articleChoiceLabel5: 'Ändra tillval',
        },
        button: {
            pay: 'Till betalning'
        },
    },
    en:{},
    fi:{},
    no:{},
    ru:{}
}